<script setup lang="ts">
import type { FormKitNode } from "@formkit/core"
import type { InputTextProps } from "./InputText.props"

const { emailValidation } = useLoqate()

async function emailCheck(node: FormKitNode) {
  const isEmailValid = await emailValidation(node.value as string)
  return isEmailValid
}
const props = withDefaults(
  defineProps<
    InputTextProps & { loqateCheck?: boolean; name?: string; type?: string }
  >(),
  {
    type: "email",
    placeholder: "",
    disabled: false,
    helpText: "",
    loqateCheck: true,
    isRequired: true
  }
)
const emit =
  defineEmits<{
    (e: "update:model-value", value: string): void
    (e: "onFocusIn"): void
    (e: "onFocusOut"): void
    (e: "onEdit"): void
  }>()
const regexpCheck = (node) => {
  const emailRegexp =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return emailRegexp.test(node.value as string)
}
const validationRules = computed(() => {
  return props.loqateCheck ? { regexpCheck, emailCheck } : {}
})
const handleEdit = () => {
  emit("onEdit")
}
</script>
<template>
  <TextfieldsInputText
    v-bind="props"
    :name="props.name ?? 'email'"
    :validation-rules="validationRules"
    :validation="`email${isRequired ? '|required' : ''}${
      loqateCheck ? '|regexpCheck|emailCheck' : ''
    }`"
    :validation-messages="{
      email: $t('textfields.emailCheck.emailValidationLabel'),
      emailCheck: $t('textfields.emailCheck.emailValidationLabel'),
      required: $t('textfields.emailCheck.emailRequired'),
      regexpCheck: $t('textfields.emailCheck.emailValidationLabel')
    }"
    @update:model-value="emit('update:model-value', $event)"
    @on-focus-in="emit('onFocusIn')"
    @on-focus-out="emit('onFocusOut')"
    @on-edit="handleEdit"
  />
</template>
